.page-wrap {
    @include clearfix;
    position: relative;
    max-width: 96%;
    margin: 1em auto;
    border: $border;
    @include mq(66em) {
        max-width: $max-width;
    }
}

.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    &-narrow {
        max-width: $max-content-width;
    }

    &-horz_padding {
        padding: 0 1em;
    }

    &-flex {
        @include mq(wide_tablets) {
            display: flex;
        }
    }
    &-hp {
        margin: 1.5rem auto;
        a {
            color: lighten($color-grey, 20%);
            &:hover {
                color: $color-white;
            }
        }
        h3 a {
            color: $color-white;
            &:hover {
                color: $color-white;
            }
        }
    }
}

.content-main {
    font-size: 0.9rem;
    width: 100%;
    min-height: 100%;
    background-color: $color-white;
    color: $text-color;
    padding: 1em;

    h1, h2, h3, h4, h5, h6 {
        color: $color-blue;
    }
    &-checkout {
        padding: 0 ;
    }
}


.section-border {
    border-bottom: $border;
}

@import '../common/content_alignment';