button, .button {
    font-size: 1rem;
    padding: 0.5em 0.75em;
    background-color: $color-blue;
    color: $color-white;
    border: none;
    transition: all 0.25s ease;
    letter-spacing: 0.9px;
    &:hover {
        background-color: lighten($color-blue, 10%);
        color: $color-grey-light;
    }
}

.button {
    display: inline-block;
}

a.button {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.button-small {
    font-size: 0.8em;
    padding: 0.25em 0.5em;
}

.button-large {
    font-size: 1.1rem;
}

.button-menu {
    padding: 0.25em 0.5em;
}

.button-arrow, a.button-arrow {
    padding-right: 1.25rem;
    .icon-arrow {
        fill: $color-white;
    }
    &:hover {
        .icon-arrow {
            fill: $color-white;
            transform: translateX(1em);
        }
    }
    &-right {
        margin-left: auto;
    }
    &-margin_right {
        margin-right: 1rem;
    }
}
