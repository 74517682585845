/* this file is for public and private/admin pages */

// include this before normalize as normalize override it for some elements
*, *:after, *:before { box-sizing: border-box; }

//@import "normalize";

input[type=radio], input[type=checkbox] { margin-right: 0.3em; }
// make sure everything shows a pointer on hover
a[href], input[type='submit'], input[type='image'], label[for], select, button {
    cursor: pointer;
}
td { vertical-align: top; }
// make images scale automatically when width of the container changes
img, object, embed { max-width: 100%; }
img { height: auto; }

h1, h2, h3, h4, h5, h6 {
    @apply font-headings leading-tight font-bold;
}
// make anchors that are inside headers look like the header, but underline when hovered
h1, h2, h3, h4 {
    &:not(.heading-keep_margin):first-child { margin-top: 0; }

    a {
        color: inherit;
        text-decoration: none;
        &:hover, &:active {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.-list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}
.-margin-no_top { margin-top: 0; }
.-margin-no_margin { margin: 0; }
.-display-hidden { display: none; }
.-display-block { display: block; }
.-link-no_underline { text-decoration: none; }
.-text-center { text-align: center; }
.-text-right { text-align: right; }
.-text-left { text-align: left; }
.-font-bold { font-weight: 700; }