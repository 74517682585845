h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
}

h1 {
    font-size: 1.85em;
}

h2, h3, h5 {
    margin-top: 1.5em;
    margin-bottom: 0.25em;
}

h4 {
    margin-top: 2em;
    margin-bottom: 0.25em;
}

h3 ~ p, h4 ~ p {
    margin-top: 0.25em;
}