.product_view-breadcrumbs {
    font-size: 0.85rem;
    margin-bottom: 1em;
    a {
        color: $color-blue;
    }
}

// @todo not a great class name –now includes images & social
.product_view-image {
    margin: 0 auto 2em;
    text-align: center;
    @include mq(wide_tablets) {
        float: right;
        max-width: 20em;
        margin: 0 0 1em;
    }
    img {
        width: auto;
        max-width: 15.6rem;
        max-height: 18rem;
    }
}

.product_view-content {
    margin-bottom: 2em;
}

.product_view-description {
    max-width: 28rem;
}

.product_view-description-small {
    font-size: 0.8rem;
    margin-top: 1em;
}

.product_view-note {
    margin-top: 1em;
}

.product_options-lists-wrap {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    @include mq(wide_tablets) {
        margin-top: 6rem;
    }
}

.product_options-list-wrap {
    width: 100%;
    margin: 0;
    padding-left: 2em;
    @include mq(wide_tablets) {
        width: auto;
        min-width: 12em;
        padding: 0;
        flex: 0 1 auto;
        margin-right: 1em;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.product_options-list-secondary {
    border-left: 5px solid $color-grey-light;
    @include mq(wide_tablets) {
        position: sticky;
        top: 1rem;
        border: none;
        align-self: flex-start;
    }
}

.product_options-list {
    min-width: 14em;
    padding: 0;
    list-style: none;
    @include mq(wide_tablets) {
        min-width: auto;
        margin-right: 2em;
    }
    a {
        text-decoration: none;
    }
}

.product_options-sublist {
    font-size: 0.8rem;
    margin: 0.5em 0 1em;
    padding-left: 1em;
}

.product_options-list_item {
    margin-bottom: 0.25em;
    &:hover {
        > .product_options-link {
            background-color: lighten(rgba($color-blue, 0.2), 35%);
            .icon-arrow {
                fill: $color-blue;
            }
        }
    }
    @include mq(wide_tablets) {
        &:hover {
            background-color: lighten(rgba($color-blue, 0.2), 35%);
        }
    }

    .product_options-list_item:hover {
        background-color: lighten(rgba($color-blue, 0.2), 35%);
        > .icon-arrow {
            fill: $color-blue;
        }
    }

    .icon-arrow {
        transition: none;
    }
    h4.product_options-heading {
        margin-top: 0.5em;
        padding-left: 1em;
        @include mq(wide_tablets) {
            margin-top: 0;
            padding-left: 0;
        }
    }
    h4.product_options-heading-details {
        padding: 0;
    }
    a:hover {
        color: $color-blue;
    }
}

.product_options-instructions {
    font-size: 0.8rem;
    margin-top: 1.1em;
    line-height: 2;
}

.product_options-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1em 0.25em;
    color: $color-blue;
}

.product_options-link-selected {
    background-color: lighten(rgba($color-blue, 0.2), 15%);
    .icon-arrow {
        fill: $color-blue;
    }
}

.product_variant-image-wrap {
    display: flex;
    align-items: center;
    margin-right: 1em;
}

.product-variant {
    width: 1.25em;
    height: 1.25em;
    min-width: 1.25em;
    margin-right: 0.75em;
    border: 1px solid $color-grey-dark;
    // so the swatch image scales to fit within the box (it's a 2x img)
    background-size: contain;
}

.product_variant-value {
    margin-right: 1em;
}

.product_details-heading {
    font-size: 0.95rem;
    margin: 0.75em 0 0.5em;
}

.product_details-details {
    display: flex;
    font-size: 0.85rem;
    margin-top: 0.9rem;
}

.product_details-note {
    font-size: 0.75rem;
    margin-top: 0.5em;
}
.product_details-note-bold {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    color: #fff;
    background-color: $primary-color;
    a {
        color: #fff;
    }
}

.product-input_wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    max-width: 11rem;
    margin-top: 0.75em;

    .form-field_wrap {
        margin-top: 1em;
    }
}

.product-cart_prompt-wrap {
    flex-basis: 100%;
    max-width: 11em;
    margin-top: 0.75em;
}

.product-cart_prompt {
    font-weight: 700;
}

.product-cart_warning_button_wrap {
    margin-top: 1rem;
    a {
        margin-right: 1.25rem;
    }
}

.product-cart_link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.product_view-cart_icon {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    fill: $color-blue;
}

.product_details-qty {
    font-size: 1rem;
    max-width: 5rem;
    margin-right: 1rem
}

.product_details-image-wrap {
    width: 3.75rem;
    height: 3.75rem;
    min-height: 3.75rem;
    min-width: 3.75rem;
    margin-top: 0.25em;
    margin-right: 1em;
    border: 1px solid $color-grey-dark;
    background-size: contain;
}

.swatch-wrap {
    display: flex;
    align-items: center;
    div:first-of-type {
        order: 2;
    }
    div:nth-of-type(2) {
        order: 1;
    }
}

.silentbox-group {
    display: flex;
    flex-flow: row wrap;
    .silentbox-item:first-of-type {
        flex-basis: 100%;
    }
}
