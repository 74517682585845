.checkout-form_wrap-wrap {
    max-width: 33em;
    margin: 0 auto;
}

.checkout-nav_wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1em;
}

.checkout_nav-prev  {
    margin-right: 1em;
    padding-bottom: 0.5em;
}

.billing-list_item {
    margin-bottom: 1em;
}

.shipping_options-wrap {
    margin-top: 1rem;
}
.checkout-pickup_help {
    margin-left: 1.3rem;
}

.checkout-form_wrap {
    margin-top: 1em;
}

.checkout_review-bottom {
    @include mq(wide_handhelds) {
        display: flex;
        margin-top: 4em;
        margin-bottom: 2em;
    }
}

.checkout_review-column {
    @include mq(wide_handhelds) {
        flex-basis: 48%;
        width: 48%;
        &:nth-of-type(odd) {
            margin-right: 1em;
        }
    }
    @include mq(tablets) {
        flex-basis: 32%;
        width: 32%;
        &:nth-of-type(odd) {
            margin-right: 1em;
        }
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
}

.confirmation-wrap {
    margin-top: 2em;
    text-align: center;
}