.alert {
    display: flex;
    justify-content: space-between;
    width: 90%;
    min-width: 10rem;
    margin: 0 auto 1em;
    padding: 0.5em;
    color: $text-color;
    background-color: $color-grey-light;
    border-left: 10px solid transparent;
    @include mq(tablets) {
        width: 70%;
    }

    // alerts nested inside forms should go the full width
    .form-form_wrap & { width: 100%; }
}

.alert-type-success {
    border-left-color: $status-type-success;
}
.alert-type-info {
    border-left-color: $status-type-info;
}
.alert-type-warning {
    border-left-color: $status-type-warning;
}
.alert-type-danger {
    border-left-color: $status-type-danger;
}

// no generic JS implemented to make the message disappear
.alert-action-right {
    // for <=IE9
    float: right;
    padding-left: 1em;
}