// for the 404 page & similar
.error_page-links_wrap {
    margin-bottom: 2.5rem;
}

.error_page-links {
    @include flex-row_wrap;
    max-width: 30rem;
    list-style: none;

    li {
        width: 100%;
        padding: 0.5rem;
        @include mq(wide_tablets) {
            width: 50%;
        }
    }
}
