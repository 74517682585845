.hp-nav_section {
    border-bottom: $border;
    text-align: center;
    @include mq(50em) {
        display: flex;
        justify-content: space-between;
        max-height: 10em;
        text-align: left;
    }

    @include mq(wide_tablets) {
        max-height: 13.4em;
    }
    a {
        color: $color-white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.hp-nav_wrap {
    max-width: 100%;
    margin-right: 2em;
    padding-left: 0.5em;

    @include mq(50em) {
        width: 50%;
    }

    @include mq(60em) {
        width: 55%;
        margin-right: 1em;
    }

    @include mq(wide_tablets) {
        width: 32.5%;
        margin-right: 0;
    }
}

.hp-nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 0.5em 0;
    list-style: none;
    text-align: right;
    @include mq(wide_tablets) {
        padding: 0.75em 0;
    }
}

.hp-nav_item {
    font-weight: 700;
    margin-bottom: 0.75em;
    &:last-of-type {
        margin-bottom: 0;
    }
    a:hover {
        color: $color-blue-light;
        text-decoration: none;
    }

    @include mq(tablets) {
        margin-bottom: 0;
    }
}

.hp-nav_img-wrap {
    display: flex;
    align-items: flex-end;
    margin-bottom: -0.2em;
    overflow: hidden;
    border-top: $border;

    @include mq(50em) {
        display: flex;
        justify-content: flex-end;
        width: 90%;
        border-top: none;
    }



    @include mq(56em) {
        width: 65.5%;
    }
}

.hp-nav_img {
    width: 33.35%;
    margin: -0.25em 0;
    border-left: $border;
    &:first-of-type {
        border-left: none;
    }
    @include mq(50em) {
        max-width: 10em;
        height: 10em;
        margin-bottom: 0;
        border-left: $border;
        &:first-of-type {
            border-left: $border;
        }
    }

    @include mq(wide_tablets) {
        max-width: 13.4em;
        height: 13.4em;
        border-left: $border;

    }

    img {
        width: 100%;
    }
}

.hp-address_wrap {
    text-align: right;
    margin: 0.5em 0 0.5em;
}

.hp-address_line {
    @include mq(wide_tablets) {
        display: inline;
        &::after {
            font-weight: 700;
            content: ' | ';
            padding: 0 0.5em;
        }

        &:last-child::after {
            content: ' ';
            padding: 0;
        }
    }
    //a href contained within span to prevent underline encompassing pipe on hover
    a {
        text-decoration: none;
        color: $color-white;
        &:hover {
            text-decoration: underline;
        }
    }
}

.hp-banner {
    padding: 0.01rem 1rem 1rem 1rem;
    border-bottom: $border;
    background-color: #a94f20;
}

.hp-banner-content {
    max-width: 40rem;
    margin: 0 auto;

    a {
        color: white;
        &:hover {
            color: #bebebe;
        }
    }
}