/** modal dialog **/
.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0,0,0,0.8);
    transition: opacity .5s ease;
}
// -enter and -leave-to are added/created by Vue.js
.md-enter {
    opacity: 0;
}
.md-leave-to {
    opacity: 0;
}

.md-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

// @todo does this will work; do we need it?
.md-modal-scroll { overflow-y: scroll; }
.md-content {
    font-size: 1rem;
    padding: 1em 1.2em;
    color: $text-color;
    background: #fff;
    max-width: 40em;
    max-height: 80%;
    overflow-y: hidden;
    z-index: 2000;
    transition: all .8s ease;
    backface-visibility: hidden;

    @include mq(wide_handhelds) {
        width: 50%;
        min-width: 320px;
    }
}
// see note above re .md-enter
.md-enter .md-content,
.md-leave-active .md-content {
    transform: scale(1.1);
}
.md-content-center { text-align: center; }

.md-close {
    float: right;
    z-index: 2200;
    font-size: 1.2em;
    color: $link-color;
    text-decoration: none;
}

.md-button_wrap {
    margin-bottom: 0.5em;
    text-align: center;
}