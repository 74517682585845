// for the maximum width of the entire content (images + text + etc)
$max-width: 64em;
// max width for content (text)
$max-content-width: 40em;

$text-color: #444;
$primary-color: $color-blue;
$link-color: $primary-color;

$border: 3px solid $color-white;

$border-radius: 2px;