.social-wrap {
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;
    }
    .social_icon-item {
        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        transition: none;
    }

}

.social_icon-item-link:hover {
    text-decoration: none;
}

.social_icon-item {
    width: 2em;
    height: 2em;
    transition: all 0.25s ease;
    text-decoration: none;

    &:last-of-type {
        margin-right: 0;
    }

    svg {
        width: 2em;
        height: 2em;
        fill: $color-grey;
        transition: fill 0.25s ease;
        &:hover {
            fill: lighten($color-blue, 10%);
        }
    }
}

.social_icon-item-pink svg {
    fill: $color-blue;
}

// @todo not a great class name - it does a lot more than make them small
.social-wrap-small {
    margin-top: 0.25em;
    svg {
        width: 2em;
        height: 2em;
        fill: $color-blue;
        transition: fill 0.25s ease;
        &:hover {
            fill: lighten($color-blue, 10%);
        }
    }
}
