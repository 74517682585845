.contact-wrap {
    margin-bottom: 2em;
    @include mq(tablets) {
        display: flex;
        margin: 0 auto 2em;
    }
}

.contact-address_wrap {
    margin-bottom: 2em;
    @include mq(tablets) {
        flex: 0 0 30%;
        width: 30%;
        max-width: 30%;
        margin-right: 4%;
        margin-bottom: 0;
    }
}

.contact-map_wrap {
    @include mq(tablets) {
        flex: 0 0 65%;
        width: 65%;
        max-width: 65%;
    }
}

.map-wrap {
    height: 0;
    overflow: hidden;
    padding-bottom: 60%; /* aspect ratio */
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);

    > iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
