.articles-list {
    max-width: 40em;
    padding: 0;
    list-style: none;
}

.articles-list_item {
    margin-bottom: 1.25em;
}

.article_list-heading {
    margin-bottom: 0;
}

.articles-list-related {
    @include mq(wide_handhelds) {
        display: flex;
        flex-flow: row wrap;
    }
}

.related_list-item {
    position: relative;
    height: 19em;
    margin-bottom: 2em;
    border: 1px solid $color-blue;
    text-align: center;

    @include mq(wide_handhelds) {
        flex-basis: 48.5%;
        width: 48.5%;
        margin-right: 1em;
        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @include mq(tablets) {
        flex-basis: 30.5%;
        width: 30.5%;
        &:nth-of-type(2n) {
            margin-right: 1em;
        }
        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
}

.related_list-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1em;
}

.related_list-image {
    width: auto;
    height: auto;
    max-width: 13em;
    max-height: 13em;
    margin: 0 auto;
}

.related_list-name {
    position: absolute;
    bottom: 1em;
    width: 90%;
    text-align: center;
}