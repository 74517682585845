.header-cart_pullout {
    flex: 0 0 100%;
    text-align: left;
    color: $text-color;
    padding-bottom: 1rem;
    background-color: $color-blue;
    a {
        color: $color-blue;
        &.button-arrow {
            color: $color_white;
            background-color: $color-blue;
            &:hover {
                background-color: lighten($color-blue, 10%);
                text-decoration: none;
            }
        }
    }
}

.cart_pullout-header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart_pullout-heading {
    margin-top: 0;
}

.cart-content-pullout {
    background-color: $color-white;
    @include mq(wide_tablets) {
        padding: 2em;
    }
}

.cart_pullout-entire_cart-wrap {
    margin: 1em 0;
    background-color: rgba($color-blue-light, 0.5);
    cursor: pointer;
    a {
        @include mq(tablets) {
            margin: 0 1em;
        }
    }
}

.cart_pullout-entire_cart {
    padding: 0.5em;
    @include mq(tablets) {
        // @todo cleanup if price is not displayed inline with other amounts
        display: flex;
        justify-content: center;
        line-height: 1;
    }
}

.cart_pullout-input {
    font-size: 1rem;
    max-width: 5em;
}

.cart_pullout-close_link {
    display: block;
    max-width: 3rem;
    margin-bottom: 1rem;
    margin-left: auto;
    font-size: 0.8rem;
}