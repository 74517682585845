// here because the admin has tailwind which imports normalize as well
@import "common/normalize";

// mixins + normalize + common
@import "common/vars",
    "public/vars",
    "common/mixins",
    "public/mixins",
    "common/media",
    "common/common";

// global styles on the most common elements
html {
    @include default-html-style;
}

body {
    background-color: $primary-color;
    color: $color-white;
}

a {
    color: $color-blue;
    transition: color 0.25s ease;
    &:hover {
        color: lighten($color-orange, 10%);
    }
}

p:last-child {
    margin-bottom: 0;
}

address { font-style: normal; }

.address--inline {
    display: inline;
}

.-text-note {
    font-size: 0.8em;
    font-style: italic;
}

// load common styles
@import "common/forms",
    "common/pagination",
    "common/alerts",
    //"common/icons",
    "common/modal";

// public only styles
@import "public/content",
    "public/buttons",
    "public/headings",
    "public/header",
    "public/header_hp",
    "public/footer",
    "public/error_page",
    "public/menu_arrow",
    "public/search",
    "public/social",
    "public/categories",
    "public/product_listing",
    "public/product",
    "public/featured_product",
    "public/cart",
    "public/cart_pullout",
    "public/checkout",
    "public/social_icons",
    "public/tinymce",
    "public/manufacturing"
    ;

// page specific
@import "public/p_index",
    "public/p_faq",
    "public/p_contact";
