.header-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;
    padding: 1em 0 0;
    margin: 0 auto;
    border-bottom: $border;
    @include mq(tablets) {
        padding: 0;
    }
    @include mq(53em) {
        align-items: flex-end;
    }
    a {
        color: $color-white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &.button {
            &:hover {
                background-color: rgba($color-white, 0.1);
                text-decoration: none;
            }
        }
    }
}

.social-wrap-header {
    list-style: none;
    padding-left: 0;
    text-align: center;
    li {
        display: inline-block;
    }
    .social_icon-item {
        &:last-of-type {
            margin-right: 0;
        }
    }
    @include mq(tablets) {
        text-align: right;
    }
}

.social_icon-item-header {
    transition: all 0.25s ease;
    &:last-of-type {
        margin-right: 0;
    }
   .social_icon-header {
        width: 1.75em;
        height: 1.75em;
        fill: $color-white;
        transition: all 0.25s ease;
        &:hover {
            fill: lighten($color-white, 10%);
        }
    }
}

.header-logo-wrap {
    flex: 0 0 3.75rem;
    padding-top: 1em;
    margin-right: 1em;
    @include mq(wide_tablets) {
        flex: 0 0 200px;
        padding-top: 0;
    }
}

.header-logo {
    display: none;
    @include mq(wide_tablets) {
        display: inline-block;
    }
}

.header-logo-small {
    display: inline-block;
    @include mq(tablets) {
        width: 52px;
        height: 58px;
    }

    @include mq(wide_tablets) {
        display: none;
    }
}

.header-content {
    @include mq(53em) {
        text-align: right;
    }
}

.header-content-right {
    display: flex;
    margin-top: 0.5em;
    border-top: $border;
    @include mq(wide_handhelds) {
        justify-content: space-between;
        flex: 1 1 100%;
        text-align: right;
    }
    @include mq(53em) {
        justify-content: flex-end;
        flex: 0 0 54%;
        margin-top: 0;
        border-top: none;
    }
    @include mq(wide_tablets) {
        flex: 0 0 46%;
    }
}

.header-content-left {
    flex: 0 0 34%;
    padding-left: 1em;
    padding-bottom: 1em;
    @include mq(53em) {
        flex: 0 0 25%;
        text-align: left;
    }
    @include mq(wide_tablets) {
        display: inline-block;
        flex: 0 0 34%;
        padding: 0 1em 1.5em;
    }
}

.header-content-center {
    flex: 0 0 43%;
    margin-right: 1em;
    margin-left: auto;
    @include mq(wide_handhelds) {
        flex: 0 0 50%;
    }
    @include mq(53em) {
        flex: 0 0 18%;
    }
}

.header-contact {
    font-size: 0.85rem;
}

.header-phone {
    display: block;
    @include mq(53em) {
        display: inline-block;
        margin-right: 0.5em;
    }
    &::after {
        display: inline-block;
        @include mq(wide_tablets) {
            padding-left: 0.5em;
            content: " | "
        }
    }
}

.header-bottom {
    flex: 0 0 100%;
    padding: 0.25em 1em;
    border-top: $border;
    text-align: center;
    @include mq(handhelds) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        padding: 0 1em;
    }
    h1, h1:first-of-type {
        font-size: 1.5em;
        margin: 0.25em 0;
    }
}

.header_bottom-login {
    font-size: 0.9rem;
}

.header-cart_icon {
    width: 1.75em;
    height: 1.75em;
    fill: $color-white;
}

.header-nav_img {
    float: right;
    width: 33.3%;
    border-left: $border;
    &:first-of-type {
        border-left: none;
        @include mq(53em) {
            border-left: $border;
        }
    }
    img {
        display: block;
        width: 100%;
        min-height: 100%;
    }
}

.header-nav_wrap {
    padding-right: 0.5em;
}

.header-nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 0 0 0.5em 3em;
    list-style: none;
    text-align: right;
    @include mq(tablets) {
        flex-flow: column nowrap;
        justify-content: flex-end;
        padding: 0 0 0.5em;
        text-align: right;

    }
}

.header-nav_item {
    font-size: 0.9rem;
    margin-bottom: 0.5em;
    font-weight: 700;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include mq(tablets) {
        font-size: 0.9rem;
        margin-bottom: 0.25em;
    }
    @include mq(58em) {
        font-size: 1rem;
    }
    @include mq(wide_tablets) {
        margin-right: 0;
    }
    a:hover {
        color: lighten($color-blue-light, 15%);
        text-decoration: none;
    }
    .icon-arrow {
        display: none;
        @include mq(71.25rem) {
            display: inline-block;
        }
    }
}
