.header-wrap-hp {
    display: block;
    padding: 1em;
    @include mq(73em) {
        padding: 0;
    }
    .header-top {
        text-align: center;

        @include mq(tablets) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 1em;
        }
        @include mq(64em) {
            margin-bottom: 0;
            padding: 1em;
        }
    }
    .header-top-left {
        max-width: 19em;
        margin: 0 auto;
        @include mq(tablets) {
            margin-left: 0;
        }
        @include mq(wide_tablets) {
            max-width: 100%;
            margin: 0;
        }
    }
    .header-top-right {
        @include mq(tablets) {
            display: flex;
            align-items: flex-start;
            text-align: right;
        }
    }
    .header-nav {
        font-size: 0.9em;
        display: grid;
        margin: 0 auto;
        grid-gap: 1.5em;
        grid-template-columns: repeat(3, minmax(1.2em, 1fr));
        padding: 1em 0 0;
        list-style: none;
        @include mq(31em) {
            grid-template-columns: repeat(4, minmax(1em, 1fr));
        }
        @include mq(52em) {
            grid-template-columns: repeat(5, minmax(1em, 1fr));
        }
        @include mq(73em) {
            display: inline;
            width: 100%;
            max-width: 100%;
            margin-bottom: 0.25em;
            padding: 0.5em 0 0 0;
        }
    }

    .header-nav_item {
        margin-bottom: 0;
        padding: 0.5em 0.25em;
        background-color: rgba($color-white, 0.1);
        border: 1px solid $color-white;
        text-align: center;
        font-weight: 700;
        &:hover {
            background-color: rgba($color-white, 0.2);
            @include mq(wide_tablets) {
                background-color: transparent;
            }
        }
        &:last-of-type {
            grid-column: 1 / span 3;
            @include mq(31em) {
                grid-column: 2 / span 2;
            }
            @include mq(40em) {
                grid-column: 4 / span 2;
            }
        }
        &:nth-last-of-type(2) {
            grid-column: 1 / span 3;
            @include mq(31em) {
                grid-column: 3 / span 2;
            }
            @include mq(40em) {
                grid-column: 2 / span 2;
            }
            @include mq(52em) {
                grid-column: 2 / span 2;
            }
        }
        &:nth-of-type(5) {
            @include mq(31em) {
                margin-right: 0;
                padding: 0.5em 1em;
            }
            @include mq(wide_tablets) {
                padding: 0.5em 0.25em;
            }
            @include mq(73em) {
                margin-right: 1.5em;
            }
        }

        @include mq(73em) {
            display: inline-block;
            margin-right: 0;
            padding: 0 0.75em 0 0;
            background-color: transparent;
            border: none;
            text-align: left;
            &::after {
                content:"|";
                padding-left: 0.75em;
                color: $color-white;
            }
            &:last-of-type {
                margin-right: 0;
                padding-right: 0;
                &::after {
                    content: "";
                    padding: 0;
                }
            }
            &:nth-of-type(5) {
                margin-right: 0;
                padding: 0 1em 0 0;
            }
        }
        a:hover {
            text-decoration: none;
            @include mq(wide_tablets) {
                text-decoration: underline;
            }
        }
    }
    .header-bottom {
        border: none;
        padding: 0 0.5em 0.5em;
        @include mq(wide_tablets) {
            padding: 0 1em;
        }
    }
    .header-bbb {
        display: none;
        @include mq(tablets) {
            display: inline-block;
        }
    }
    .social-wrap-header {
        @include mq(tablets) {
            margin-top: 0.75em;
            margin-bottom: 0;
        }
    }
}

// @todo design - not the best class name - but can't think of anything better atm
.header-cart_wrap-hp {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    max-width: 20em;
    margin: 0 auto;
    @include mq(tablets) {
        flex-flow: column wrap;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 1em;
        margin-bottom: 0.25em;
    }
    .header_hp-links_wrap {
        order: 3;
        margin-top: 1em;
        @include mq(tablets) {
            order: 1;
            flex-basis: 15%;
            margin-top: 0;
        }
    }
    .header_hp-contact_wrap {
        order: 1;
        @include mq(tablets) {
            order: 2;
            flex-basis: 100%;
        }
    }
    .social-wrap-header {
        order: 2;
        margin-right: 1em;
        margin-bottom: 0;
        @include mq(tablets) {
            order: 3;
            flex-basis: 80%;
            margin-right: 0;
        }
    }
}

.header_hp-link {
    &:first-of-type {
        padding-right: 0.5rem;
        &:after {
            display: inline-block;
            content: "|";
            padding-left: 0.75rem;
            font-weight: 700;
        }
    }
}

.header_hp-anniversary {
    display: block;
    margin: 1rem 0;
    padding-left: 1.7rem;
    text-align: center;
    @include mq(tablets) {
        order: 3;
        width: 100%;
    }
    @include mq(wide_tablets) {
        order: initial;
        width: auto;
        margin: 0 1rem;
    }
}