.categories-nav_wrap-wrap {
    flex: 0 0 100%;
    width: 100%;
    padding: 1em;
    background-color: $color-grey;
    @include mq(wide_tablets) {
        display: block;
        flex: 0 0 23%;
        width: 23%;
        height: auto;
        min-height: 100%;
    }

    a {
        color: $color-white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.categories-nav_wrap {
    display: none;

    @include mq(wide_tablets) {
        display: block;
    }
}

.categories-nav_wrap-mobile {
    display: block;
}

.categories_nav-view_link {
    display: block;
    @include mq(wide_tablets) {
        display: none;
    }
}
.categories_nav-view_link-open {
    text-align: right;
}

.categories_nav-header-all {
    display: none;
    @include mq(wide_tablets) {
        display: block;
        margin-top: 2rem;
    }
}

.categories_nav-heading {
    font-size: 1.1rem;
    margin: 0 0 0.5em;
}

.categories-nav {
    font-size: 0.85rem;
    margin: 0;
    padding-left: 0.75em;
    list-style: none;
}

.categories_nav-item {
    margin-bottom: 0.4rem;
}

.subcategories-wrap {
    width: 30%;
    height: 100%;
    padding: 1em;
    background-color: rgba($color-white, 0.2);
    a { color: $color-white; }
}

.subcategories_nav-item {
    margin-bottom: 0.25em;
}

.categories-list {
    display: flex;
    flex-flow: row wrap;
    margin-top: 2em;
    padding: 0;
    list-style: none;
}

.category-list_item {
    width: 80%;
    margin: 0 auto 1rem;

    border: 1px solid rgba(0,0,0,0.1);
    border-bottom-color: rgba(0,0,0,0.25);
    border-left-color: rgba(0,0,0,0.2);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    text-align: center;
    &:hover {
        border-color: rgba($color-grey-dark, 0.5);
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
    }
    @include mq(wide_handhelds) {
        width: 48%;
        &:nth-of-type(2n) {
            margin-right:0;
        }
    }
    @include mq(55em) {
        width: 30.5%;
        margin: 0 2.25em 2.25em 0;

        &:nth-of-type(2n) {
            margin-right: 2.25em;
        }

        &:nth-of-type(3n) {
            margin-right:0;
        }
    }
    @include mq(70em) {
        width: 17.3%;
        margin-right: 2.25em;
        margin-bottom: 2.25em;
        &:nth-of-type(3n) {
            margin-right: 2.25em;
        }
        &:nth-of-type(5n) {
            margin-right:0;
        }
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem;
        text-decoration: none;
    }
}
