.footer-wrap {
    max-width: 96%;
    margin: 0 auto 1em;
    padding: 1em 1em 0.75em;
    border: $border;
    background-color: $color-grey;
    @include mq(66em) {
        max-width: $max-width;
    }

    a {
        color: $color-white;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-nav-wrap {
    font-size: 0.8em;
    text-align: center;
    @include mq(tablets) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
        grid-gap: 2em;
        text-align: left;
    }
}

.footer-user-wrap {
    font-size: 0.8em;
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
    }
}

.footer-nav {
    margin-top: 0;
    margin-bottom: 2em;
    padding: 0;
    list-style: none;
    text-align: center;
    @include mq(tablets) {
        margin: 0;
        text-align: left;
    }
}

.footer-nav-user {
    margin-bottom: 0;
    @include mq(tablets) {
        display: flex;
        justify-content: flex-end;
        .footer-nav_item {
            margin-left: 0.75em;
            &::after {
                content:"|";
                padding-left: 0.75em;
            }
            &:last-of-type {
                margin-right: 0;
                &::after {
                    content: "";
                    padding-left: 0;
                }
            }
        }
    }
}

.footer-nav_item {
    margin-bottom: 0.5rem;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include mq(tablets) {
        margin-bottom: 0.12rem;
    }
}

.footer-copyright {
    font-size: 0.7rem;
    color: $color-grey-light;
    @include mq(tablets) {
        float: right;
    }
}

.footer-address_wrap {
    text-align: center;
}

.footer-social_wrap {
    margin: 1em 0 0;
    text-align: center;

    @include mq(tablets) {
        display: inline-block;
        margin: 0;
        text-align: right;
    }
    .social-wrap {
        order: 1;
        text-align: center;
        margin-top: 0;
        margin-right: 1em;
        @include mq(tablets) {
            margin-right: 0;
            text-align: right;
        }
    }
    .social_icon-item {
        margin-right: 0.15em;
        transition: all 0.25s ease;
        text-decoration: none;

        &:last-of-type {
            margin-right: 0;
        }

        svg {
            width: 1.75em;
            height: 1.75em;
            fill: $color-white;
            transition: all 0.25s ease;
            &:hover {
                fill: lighten($color-white, 10%);
            }
        }
    }


}

.footer-bbb {
    order: 2;
}

.footer-anniversary {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1rem;
    @include mq(tablets) {
        margin-top: -3em;
    }
}