.icon-arrow {
    display: inline-block;
    transition: all 0.25s ease;
    fill: $color-white;
    width: 0.8em;
    height: 0.8em;
    margin-left: 0.5em;
}

.icon-arrow-wrap:hover {
    .icon-arrow {
        transform: translateX(1em);
        fill: $color-blue-light;
    }
}

a.icon-arrow-wrap:hover {
    text-decoration: none;
}