.pagination-wrap {
    // clearfix fixes Safari bug that causes pagination to show up beside other element
    @include clearfix;
    margin: 1em auto;
    text-align: center;
    a { text-decoration: none; }
}

.pagination-item {
    display: none;
    width: 3.25em;
    padding: 0.25em 0.15em;
    color: lighten($text-color, 50%);
    @include mq(54em) {
        display: inline-block;
    }
}

a.pagination-item {
    padding: 0.25em 0.15em;
    color: $link-color;
    border-radius: $border-radius;
    &:hover {
        background: $pagination-link-hover;
    }
}

.pagination-item-mobile { display: inline-block; }

.pagination-item-current {
    position: relative;
    padding: 0.25em 0.15em;
    color: $text-color;
    border: 1px solid rgba($color-grey-dark, 0.15);
    border-radius: $border-radius;
}