.header-search-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    @include text-input() {
        width: 10em;
        -webkit-appearance: none;
        border-radius: 0;
        background-color: $color-white;
    }
    @include mq(tablets) {
        justify-content: flex-end;
        margin-bottom: 0.5em;
    }
}

.header-search_form {
    display: none;
    margin-top: 0.5em;
    @include mq(tablets) {
        display: block;
        margin: 0.5em 0 0 auto;
    }
}

.header-search-mobile {
    display: block;
    margin: 0.5em 0;
    @include mq(tablets) {
        display: none;
    }
}

.header-cart_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    @include mq(tablets) {
        justify-content: flex-start;
    }
    @include mq(wide_tablets) {
        margin: 0;
    }
    svg {
        margin-right: 1em;
    }
    span {
        font-size: 0.8rem;
    }
}

.button-search {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: $color-white;
    border: 1px solid $color-white;
    border-left: none;
}


.search-form {
    font-size: 1rem;
    margin-top: 0.5em;
    @include mq(tablets) {
        display: none;
    }
}
.search-form-button {
    padding: 0.25rem 1rem;
}
