.cart-nav {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1em;
    @include mq(wide_tablets) {
        flex-flow: row nowrap;
    }
}

.cart-nav_item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 1rem 2rem;
    text-decoration: none;
    color: $color-grey;
    line-height: 1;
}

.cart-nav_item {
    display: flex;
    justify-content: center;
    font-size: 0.85rem;
    width: 49%;
    margin-right: 0.15rem;
    margin-bottom: 0.15rem;
    background-color: lighten($color-grey, 15%);
    transition: all 0.25s ease;
    &:first-of-type {
        border-left: 1px solid $color-grey-light;
    }
    &:hover {
        background-color: lighten($color-grey,25%);
        &:after {
            background-color: lighten($color-grey,25%);
        }
    }
    @include mq(wide_handhelds) {
        width: 32.5%;
        font-size: 0.95rem;
    }
    @include mq(wide_tablets) {
        position: relative;
        flex: 1 1 auto;
        width: auto;
        margin-right: 0;
        &:after {
            position: absolute;
            top: 0.45em;
            right: -1.4em;
            transform: rotate(45deg);
            content: "";
            width: 2.75em;
            height: 2.75em;
            background-color: lighten($color-grey, 15%);
            border-top: 3px solid $color-white;
            border-right: 3px solid $color-white;
            transition: all 0.25s ease;
            z-index: 1000;
            border-radius: 4px;
        }
        &:last-of-type:after {
            display: none;
        }
    }
    @include mq(73.25em) {
        font-size: 1.05rem;
    }

    &-active {
        color: $color-blue;
        background-color: $color-grey-light;
        &:hover {
            background-color: $color-grey-light;
            a { color: lighten($color-blue,10%); }
            &:after {
                background-color: $color-grey-light;
            }
        }

        @include mq(wide_tablets) {
           &:after {
                background-color: $color-grey-light;
            }
        }

        .cart-nav_item-link { color: $color-blue; }
        .cart-icon {
            fill: $color-blue;
        }
    }
    &-complete {
        background-color: lighten($color-grey, 25%);
        &:after {
            background-color: lighten($color-grey, 25%);
        }
    }
    a { color: $color-grey; }
}

.cart-content {
    padding: 1em;
}

.cart-icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5em;
    fill: $color-grey;
}

.cart-nav_item-text {
    line-height: 1;
}

.cart-wrap {
    flex: 0 0 100%;
    padding: 0;
    text-align: left;
    color: $text-color;
    a { text-decoration: none; }
}

.cart_totals-wrap {
    font-size: 0.9rem;
    margin: 2rem 0 1rem;
    padding-top: 1.75rem;
    text-align: right;
    border-top: 1.5px solid $color-blue-light;
    .button-arrow {
        max-width: 7rem;
        margin: 1em 0 1em auto;
    }
    &-updates {
        margin: 0;
        padding-top: 0;
        border-top: none;
    }
}

.cart-total {
    margin-bottom: 0.5em;
    @include mq(tablets) {
        display: flex;
        justify-content: flex-end;
    }
}

.cart-product_details {
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $color-grey-light;
    @include mq(wide_handhelds) {
        margin-right: 1em;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
    @include mq(tablets) {
        width: 28em;
    }
    @include mq(wide_tablets) {
        width: 33em;
    }

    > div {
        margin-bottom: 0.25em;
    }
    .cart-product_name {
        margin-bottom: 0.5rem;
    }
}

.cart-product {
    padding: 1em;
    position: relative;
    width: 100%;
    font-size: 0.85rem;
    color: $text-color;
    border-bottom: 1px solid $color-white;
    &:last-of-type {
        margin-bottom: 1em;
    }
    &:nth-child(odd) {
        background-color: rgb(219,221,228);
    }
    &:nth-child(even) {
        background-color: $color-grey-light;
        .cart-product_details {
            border-bottom: 1px solid $color-grey;
            @include mq(wide_handhelds) {
                border-bottom: none;
            }
        }
    }
    &:hover {
        background-color: rgb(183,187,200);
    }

    @include mq(tablets) {
        font-size: 0.9rem;
        display: flex;
    }
}

.cart-product_img {
    min-width: 6.5rem;
    max-width: 6.5rem;
    margin-right: 2em;
    margin-bottom: 1em;
    @include mq(tablets) {
        margin-bottom: 0;
    }
}

.cart-product_details-wrap {
    @include mq(wide_handhelds) {
        display: flex;
    }
}

.cart-product_name {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: lighten($color-blue, 10%);
    a {
        text-decoration: none;
        &:hover {
            color: lighten($color-blue, 10%);
            text-decoration: underline;
        }
    }
}

.cart-product_table {
    display: table;
    flex: 1 1 auto;
    margin-left: auto;
    text-align: right;
    @include mq(tablets) {
        margin-left: 0;
        text-align: left;
    }
}

.cart_product-label {
    color: $color-blue;
    font-weight: 700;
}

// @todo design - may need to cleanup these class names
.cart-product_totals {
    position: relative;
    margin: 0;

    @include mq(tablets) {
        display: table-row;
        margin: 0;
        text-align: right;
        > div {
            display: table-cell;
            width: 8rem;
        }
        .cart-product_subtotal {
            width: 5rem;
        }
    }
}

.cart-product_price {
    margin-bottom: 0.5em;
    @include mq(tablets) {
        margin-bottom: 0;
    }
}

.cart-product_qty {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.5em;
    @include mq(tablets) {
        display: block;
        text-align: left;
        padding-left: 2.5rem;
    }
}

.cart-quantity_sub_text {
    font-size: 0.8rem;
    font-style: italic;
    flex-basis: 100%;
}

.cart-product-headings {
    display: none;
    @include mq(tablets) {
        display: flex;
    }

    &:first-of-type {
        border-top: none;
    }

    &:nth-of-type(odd) {
        background-color: $color-white;
    }
}

.cart_product-heading-mobile {
    display: inline-block;
    width: 5em;
    font-weight: 700;
    @include mq(tablets) {
        display: none;
    }
    &-qty {
        // fixes strange alignment issue
        margin-right: 0.25rem;
    }
}

.cart_product-amt-mobile {
    display: inline-block;
    width: 7em;
    @include mq(tablets) {
        width: auto;
    }
}

.cart-updating {
    width: 100%;
    height: 100%;
    padding: 0.5em 0.75em;
    background: rgba($color-blue, 0.8);
    color: $color-white;
}

.cart_msg-added {
    margin-top: 0.25rem;
    text-align: right;
}

.cart-total-label {
    display: inline-block;
    width: 5rem;
    margin-right: 1rem;
}

.cart-total-amount {
    display: inline-block;
    width: 7rem;
    text-align: right;
}

.cart-total-total {
    font-size: 1.1rem;
    font-weight: 700;
}

.cart-empty_cart {
    display: none;
    font-size: 0.8rem;
    @include mq(tablets) {
        display: block;
        margin: 0;
    }
    &-mobile {
        display: block;
        margin-top: 1em;
        @include mq(tablets) {
            display: none;
        }
    }
}

.cart_totals-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1em;
}

.cart_totals-bottom-links {
    padding-bottom: 0.45em;
}

.cart-separator {
    height: 1.5px;
    width: 100%;
    margin: 2em auto;
    background-color: $color-blue-light;
}
