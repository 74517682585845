.content-img-center {
    display: block;
    margin: 1em auto;
}
.content-img-left {
    float: left;
    margin: 1em 1em 1em 0;
}
.content-img-right {
    float: right;
    margin: 1em 0 1em 1em;
}