.manufacturing-section {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    max-width: 80%;
    margin: 2em auto;
    padding: 2em;
    border: 1px solid $color-blue;
    @include mq(tablets) {
        justify-content: center;
        > div {
            width: 47%;
            &:first-of-type {
                margin-right: 2em;
            }
        }
        .manufacturing-child-full_width {
            width: 100%;
        }
    }
}

.manufacturing-content {
    @include mq(tablets) {
        flex-basis: 60%;
        width: 60%;
    }
}

.fabrics-list_wrap {
    > ul {
        margin-right: 1em;
    }
}