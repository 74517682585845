.product_list-subheading {
    margin-top: 0.5em;
}
.product_list-record_count {
    font-size: 0.85em;
    text-align: right;
    color: $color-grey;
}
.product-list {
    margin-top: 1em;
    padding: 0;
    list-style: none;
}
.product_list-item {
    &:nth-child(odd) {
        background-color: rgba($color-blue, 0.1);
    }
    &:hover {
        background-color: rgba($color-blue, 0.2);
    }
}
.product_list-link {
    padding: 1em;
    border-bottom: 1px solid lighten($color-grey, 10%);
    text-decoration: none;
    color: $text-color;
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;
    }
    &:first-of-type {
        border-top: 1px solid lighten($color-grey, 10%);
    }
}
.product_list-description {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    @include mq(tablets) {
        flex: 0 0 60%;
        width: 60%;
        max-width: 60%;
        margin-right: 1em;
        margin-bottom: 0;
    }
}

.product_list-name {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    @include mq(tablets) {
        margin-bottom: 0;
    }
}
.product_list-other_details {
    font-size: 0.8em;
    flex: 0 0 19%;
    color: $color-grey;
}
.product_list-image {
    margin-bottom: 0.5rem;
    text-align: center;
    @include mq(tablets) {
        flex: 0 0 18%;
        margin-right: 1em;
        margin-bottom: 0;
    }
    img {
        width: auto;
        max-height: 6.25rem;
    }
}